import React from "react";
import { Link } from "react-router-dom";
import KentuckyProud from "../images/kentucky-proud.png";
import RootedInShelby from "../images/rooted-in-shelby.png";
import StsfBadge from "../images/home/STSF-Turf-Sod-Circle-Logo-09.png";
import IosImage from "../images/home/stsf-ios-homepage.png";

import { Helmet } from "react-helmet";
import "react-slideshow-image/dist/styles.css";
import "./Home.css";
import "./shared.css";
import {
  GetScreenScale,
  IsSmallScreen,
} from "../services/PageWidthCalculator.js";
import GrassyBulletPointText from "../components/GrassyBulletPointText.js";

function Home() {
  const scaleWidth = GetScreenScale();
  const isSmallScreen = IsSmallScreen();

  let isIos =
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: scaleWidth,
        margin: scaleWidth !== "100%" ? "auto" : "none",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Superior Turf-Sod Farm</title>
        <link rel="canonical" href="https://stsf.com/home" />
        <meta name="description" content="Superior Sod, Superior Service" />
      </Helmet>

      {/* Banner */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flex: 1,
        }}
      >
        <div className="content">
          <div className="overlay">
            <div
              className="overlayText"
              style={{
                color: "white",
                fontFamily: "GolosText-Bold",
                fontSize: isSmallScreen ? "14pt" : "36pt",
                paddingTop: "50px",
                userSelect: "none",
              }}
            >
              <i>"SUPERIOR SOD, SUPERIOR SERVICE"</i>
            </div>
          </div>
          {isIos ? (
            <video width="100%" autoPlay muted loop>
              <source
                src="https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/STSF-Turf-Sod-Services-Video-Home-Page.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={IosImage} alt="STSF IOS Banner" width={"100%"} />
          )}
        </div>
      </div>

      {/* OUR WORK WITH .... */}
      <div
        style={{
          width: "70%",
          margin: scaleWidth !== "70%" ? "auto" : "none",
          display: "flex",
          flexDirection: "column",
          flex: "1",
          marginTop: "2em",
          paddingTop: "1.5em",
          paddingBottom: "1.5em",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            userSelect: "none",
          }}
          className="stsfHeaders"
        >
          OUR WORK WITH RESIDENTIAL & BUSINESS LOCATIONS
        </h2>
        <p className="stsfBody" style={{ paddingTop: "1.5em" }}>
          We are a small local company, with a region wide establishment that
          delivers high quality results for businesses and residential clients
          alike. If you are doing a small or large job, renovating or
          establishing a yard, interested in erosion control, completing a
          comercial project or just wanting to add that finishing touch, call us
          at 888-Turf-Sod. We offer competitive prices for your Turf-Sod needs.
          Our Turf-Sod is a specialized blend of fescue/bluegrass that is
          perfect for our climate zone. It is also grown for 15 months so that
          the Turf-Sod is fully mature before harvesting.
        </p>

        {/* SECTIONS */}
        <div
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            paddingTop: "55px",
          }}
        >
          <Link
            to="/residential-services"
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              textDecoration: "none",
            }}
          >
            <div
              style={{
                backgroundImage: `url(https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/home/STSF-Turf-Sod-Services-Residential.jpg)`,
                height: "10em",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontFamily: "GolosText-Bold",
                fontSize: "16pt",
                margin: "15px",
                flex: 1,
                userSelect: "none",
              }}
              className="pageImageLinks"
            >
              RESIDENTIAL
            </div>
          </Link>

          <Link
            to="/business-services"
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              textDecoration: "none",
            }}
          >
            <div
              style={{
                backgroundImage: `url(https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/home/STSF-Turf-Sod-Services-Business.jpg)`,
                height: "10em",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontFamily: "GolosText-Bold",
                fontSize: "16pt",
                margin: "15px",
                flex: 1,
                userSelect: "none",
              }}
              className="pageImageLinks"
            >
              BUSINESS
            </div>
          </Link>
          <Link
            to="/turfsodcare"
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              textDecoration: "none",
            }}
          >
            <div
              style={{
                backgroundImage: `url(https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/home/STSF-Turf-Sod-Services-Care.jpg)`,
                height: "10em",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontFamily: "GolosText-Bold",
                fontSize: "16pt",
                margin: "15px",
                flex: 1,
                userSelect: "none",
              }}
              className="pageImageLinks"
            >
              CARE
            </div>
          </Link>
          <Link
            to="/contact"
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              textDecoration: "none",
            }}
          >
            <div
              style={{
                backgroundImage: `url(https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/home/STSF-Turf-Sod-Services-Contact.jpg)`,
                height: "10em",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                color: "white",
                fontFamily: "GolosText-Bold",
                fontSize: "16pt",
                margin: "15px",
                userSelect: "none",
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
              className="pageImageLinks"
            >
              CONTACT
            </div>
          </Link>
        </div>
      </div>

      {/* Turf Sod Features */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          margin: "auto",
          backgroundImage: `url(https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/home/STSF-Turf-Sod-Services-Green-Grass.jpg)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          flex: 1,
          userSelect: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",

            paddingTop: "25px",
            paddingBottom: "25px",
            width: "100%",
          }}
        >
          <div
            style={{
              margin: "none",
              alignItems: "center",
              display: "flex",
              flex: 5,
            }}
          >
            <h1
              style={{
                color: "white",
                userSelect: "none",
                paddingLeft: "25px",
              }}
              className="stsfHeaders"
            >
              TURF-SOD FEATURES
            </h1>
          </div>
          <div
            style={{
              paddingLeft: "10px",
              display: "flex",
              alignContent: "flex-end",
              flex: 1,
            }}
          >
            <img
              src={StsfBadge}
              alt="STSF White Badge"
              style={{
                maxWidth: "7em",
                paddingRight: "25px",
              }}
            />
          </div>
        </div>
        <div
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingBottom: "20px",
          }}
        >
          <GrassyBulletPointText text="Instant beautification of landscape" />
          <GrassyBulletPointText text="Roots develop rapidly where seeding would fail" />
          <GrassyBulletPointText text="Most cost-effective method for controlling soil erosion" />
          <GrassyBulletPointText text="Absorbs, retains, and filters water and run-off better than seeded areas" />
        </div>
      </div>

      {/* Who we are */}
      <div
        style={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          padding: "10%",
          alignContent: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignContent: "center",
            justifyContent: "flex-start",
            paddingRight: "20px",
          }}
        >
          <h1 className="stsfHeaders">WHAT WE DO</h1>
          <p
            className="stsfBody"
            style={{ paddingTop: "1.5em", paddingRight: "10px" }}
          >
            STSF is located just outside of Shelbyville, Kentucky in the heart
            of Bluegrass country. We offer a Turf-Type Tall Fescue/Bluegrass Sod
            that does especially well in our region. Our on staff Turf
            Management Consultant assists us in growing a Superior turf product
            for our customers
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/home/STSF-Turf-Sod-Services-Home-Page.jpg"
            width="100%"
            alt="Unrolling fresh sod"
          />
        </div>
      </div>

      {/* STAMPS */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "65px",
          flex: 1,
        }}
      >
        <img
          src={KentuckyProud}
          width={isSmallScreen ? "30%" : "15%"}
          alt="Kentucky Proud Stamp"
          style={{ paddingLeft: "35px", paddingRight: "35px" }}
        />
        <img
          src={RootedInShelby}
          width={isSmallScreen ? "30%" : "15%"}
          alt="Rooted in Shelby Logo"
          style={{ paddingLeft: "35px", paddingRight: "35px" }}
        />
      </div>
    </div>
  );
}

export default Home;
