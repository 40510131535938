import React from "react";
import { Helmet } from "react-helmet";
import {
  GetScreenScale,
  IsSmallScreen,
} from "../services/PageWidthCalculator.js";
import "./ContactUs.css";
import "./shared.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import CollapsibleInfo from "../components/CollapsableInfo";
import GoogleMapReact from "google-map-react";

import {
  faFacebookSquare,
  faGoogle,
  faYelp,
} from "@fortawesome/free-brands-svg-icons";

function ContactUs() {
  const scaleWidth = GetScreenScale();
  const isSmallScreen = IsSmallScreen();

  const defaultProps = {
    center: {
      lat: 38.2,
      lng: -85.15,
    },
    zoom: 10,
  };

  const Marker = ({ text, link }) => (
    <div
      onClick={() => window.open(link)}
      style={{
        cursor: "pointer",
        position: "relative",
        transform: "translate(-50%, -50%)",
      }}
    >
      <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" color="red" />
      <div
        style={{
          fontWeight: "bold",
          fontSize: 12,
          color: "black",
        }}
      >
        {text}
      </div>
    </div>
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: scaleWidth,
        margin: scaleWidth !== "100%" ? "auto" : "none",
        paddingTop: "35px",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Superior Turf-Sod Farm | Contact</title>
        <link rel="canonical" href="https://stsf.com/contact" />
        <meta
          name="description"
          content="We have high availability for all business and residential needs."
        />
      </Helmet>
      {/* FAQ */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "3%",
          marginRight: "3%",
          paddingBottom: "3%",
        }}
      >
        <h1
          style={{
            textAlign: "left",
            paddingBottom: "25px",
          }}
          className="stsfHeaders"
        >
          FREQUENTLY ASKED QUESTIONS
        </h1>
        <div
          style={{
            border: "10px solid #3AB5AC",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <CollapsibleInfo
              question="Do you sell to individuals?"
              answer="Yes!<br /> <br /> In addition to builders and landscape contractors, we also sell to individuals. <br />
              If you need a small amount of sod, we will cut standard small rolls for you, "
            />
          </div>
          <div style={{ borderTop: "5px solid #3AB5AC" }}>
            <CollapsibleInfo
              question="What size are your rolls of Sod?"
              answer="Our standard small rolls measure 18 inches wide by 6 feet long.  Each roll is 1 square yard or 9 square feet."
            />
          </div>
          <div style={{ borderTop: "5px solid #3AB5AC" }}>
            <CollapsibleInfo
              question=" Do you sell Big rolls to individuals?"
              answer="Yes.  If you have the capacity to handle big rolls we can sell you those as well.  Each big roll is 30 square yards or 270 square feet and measures 2.5 feet wide by 108 feet long."
            />
          </div>
          <div style={{ borderTop: "5px solid #3AB5AC" }}>
            <CollapsibleInfo
              question="What type of sod do you have?"
              answer="Our  proprietary blend consists of three different Turf-Type Tall Fescues (85%) and two varieties of Kentucky bluegrass
              (15%).  The blend is specifically selected for our growing region and is as appropriate for a residential lawn as it is an athletic playing field."
            />
          </div>
          <div style={{ borderTop: "5px solid #3AB5AC" }}>
            <CollapsibleInfo
              question="Where may I see your sod?"
              answer="In addition to the content in our website, our Facebook page is updated weekly with pictures of our fields and installation jobs."
            />
          </div>
          <div style={{ borderTop: "5px solid #3AB5AC" }}>
            <CollapsibleInfo
              question="How do I figure out how much sod I need?"
              answer="To calculate how much sod you will need you will first need to measure the length and width of the area you are wanting to cover. <br /> <br />
              With your measurements, multiply the length by the width to get the square footage of the area. <br /> Then divide that number by 9, which gives you the square yardage of the area. <br />
              <br />The square yardage of the area is what we will need when you go to place an order with us."
            />
          </div>
          <div style={{ borderTop: "5px solid #3AB5AC" }}>
            <CollapsibleInfo
              question="How do I order my sod?"
              answer="To order your sod, you can call or email us using the contact info above. <br /><br /> For amounts under 400 square yards (3,600 square feet), we will schedule you for a field pick up at our location. <br /> For amounts in excess of 400 square yards (3,600 square feet) we will schedule an on-site estimate if required."
            />
          </div>
          <div style={{ borderTop: "5px solid #3AB5AC" }}>
            <CollapsibleInfo
              question="When can I pick up sod after ordering?"
              answer="Field pick ups are done by schedule at 9:00 AM, Monday through Friday (weather permitting). Saturdays can be scheduled by special appointment. <br /> <br />
               Please call to a day or two in advance and we will schedule your pick up time and day."
            />
          </div>
          <div style={{ borderTop: "5px solid #3AB5AC" }}>
            <CollapsibleInfo
              question="What if I don’t have a way to pick up the sod?"
              answer="No problem!  <br /><br />We work with several local landscapers and can refer you to one that will be happy to quote delivering your sod to you."
            />
          </div>

          <div style={{ borderTop: "5px solid #3AB5AC" }}>
            <CollapsibleInfo
              question="How should I prepare the area I want to sod?"
              answer="The area should be free of undesirable grass or weeds. Once all weeds and grass are removed you can then till and rake the area smooth.  <br /> <br />Alternatively, you can rent a walk-behind harley rake to prepare the soil."
            />
          </div>
        </div>
      </div>

      {/* Contact Section */}
      <div
        style={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          marginLeft: isSmallScreen ? "none" : "3%",
          marginRight: isSmallScreen ? "none" : "3%",
          flex: 2,
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: isSmallScreen ? "none" : "3%",
            marginRight: isSmallScreen ? "none" : "3%",
            flex: 3,
            margin: "auto",
          }}
        >
          <h1 style={{ textAlign: "left" }} className="stsfHeaders">
            CONTACT
          </h1>
          {/* PHONE */}
          <div className="stsfMiniHeader" style={{ paddingTop: ".75em" }}>
            <a href="tel:123-456-7890" className="ClickableElement">
              888-887-3763
            </a>
          </div>
          {/* EMAIL */}
          <div className="stsfMiniHeader" style={{ paddingTop: ".75em" }}>
            <a
              href="mailto:info@stsf.com"
              target="_blank"
              rel="noopener noreferrer"
              className="ClickableElement"
            >
              info@stsf.com
            </a>
          </div>
          {/* ADDRESS */}
          <div className="stsfMiniHeader" style={{ paddingTop: ".75em" }}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/maps/dir//Superior+Turf-Sod+Farm+Inc,+111+Picketts+Dam+Rd,+Shelbyville,+KY+40065/@38.1367863,-85.2986435,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8869b8cd2905fb5f:0x19a3b8d269b37965!2m2!1d-85.296458!2d38.136756"
              className="ClickableElement"
            >
              111 Picketts Dam Rd <br />
              Shelbyville, KY
              <br />
              40065-9208
            </a>
          </div>
          <div style={{ textAlign: "left", paddingTop: "20px" }}>
            <a
              href="https://www.facebook.com/profile.php?id=100030340816997"
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: "25px" }}
            >
              <FontAwesomeIcon
                icon={faFacebookSquare}
                size="2x"
                className="ClickableElement"
              />
            </a>
            <a
              href="https://www.google.com/maps/dir//Superior+Turf-Sod+Farm+Inc,+111+Picketts+Dam+Rd,+Shelbyville,+KY+40065/@38.1367863,-85.2986435,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8869b8cd2905fb5f:0x19a3b8d269b37965!2m2!1d-85.296458!2d38.136756"
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: "25px" }}
            >
              <FontAwesomeIcon
                icon={faGoogle}
                size="2x"
                className="ClickableElement"
              />
            </a>
            <a
              href="https://www.yelp.com/biz/superior-turf-sod-farm-shelbyville?osq=superior+turf+and+sod"
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: "25px" }}
            >
              <FontAwesomeIcon
                icon={faYelp}
                size="2x"
                className="ClickableElement"
              />
            </a>
          </div>
        </div>

        {/* MAP */}
        <div
          style={{
            height: "25vh",
            width: "75%",
            flexDirection: "column",
            minWidth: "300px",
            minHeight: "350px",
            paddingBottom: isSmallScreen ? "110px" : "80px",
            paddingTop: isSmallScreen ? "50px" : "none",
            margin: "auto",
          }}
        >
          <h1
            style={{
              textAlign: isSmallScreen ? "left" : "right",
              paddingBottom: "20px",
            }}
            className="stsfHeaders"
          >
            WHERE TO FIND US
          </h1>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyChj8Ehcjh5XnhVgD7UsNwA5MGKnHKLFRE",
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <Marker
              lat={38.136909}
              lng={-85.296455}
              text={"STSF Office"}
              link={
                "https://www.google.com/maps/dir//Superior+Turf-Sod+Farm+Inc,+111+Picketts+Dam+Rd,+Shelbyville,+KY+40065/@38.1367863,-85.2986435,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8869b8cd2905fb5f:0x19a3b8d269b37965!2m2!1d-85.296458!2d38.136756"
              }
            />
            <Marker
              lat={38.266646}
              lng={-85.13042}
              text={"STSF Farm"}
              link={
                "https://www.google.com/maps/dir//STSF+Sod+Farm/@38.2384234,-85.2550623,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8869e8f22ba9347d:0xd0d1d690e5cf0c4c!2m2!1d-85.1301693!2d38.2648144"
              }
            />
          </GoogleMapReact>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
