import { useEffect, useState } from "react";
import { pageWidths } from "../page-widths";

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const GetScreenScale = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isSmallDevice =
    windowDimensions.width < pageWidths.smallWidth && windowDimensions.width;
  const isMediumDevice =
    windowDimensions.width >= pageWidths.smallWidth &&
    windowDimensions.width < pageWidths.largeWidth;

  return isSmallDevice ? "100%" : isMediumDevice ? "100%" : "80%";
};

export const IsSmallScreen = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isSmallDevice =
    windowDimensions.width < pageWidths.smallWidth && windowDimensions.width;

  return isSmallDevice;
};
