import React from "react";
import GrassyBulletPoint from "../svgs/grassy-bullet-point.svg";
import "../pages/shared.css";

function GrassyBulletPointText({ text, color }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        padding: "15px",
        fontFamily: "GolosText-Bold",
        fontSize: "18pt",
        color: "white",
      }}
    >
      <img
        src={GrassyBulletPoint}
        width="25px"
        alt="Grassy Bullet Points"
        color="white"
      />
      <span style={{ paddingLeft: "15px", color: "white" }}>
        <i>{text}</i>
      </span>
    </div>
  );
}

export default GrassyBulletPointText;
