import React from "react";
import { Helmet } from "react-helmet";
import {
  GetScreenScale,
  IsSmallScreen,
  getWindowDimensions,
} from "../services/PageWidthCalculator.js";
import GrassyBulletPointText from "../components/GrassyBulletPointTextGreen.js";
import PageImageBanner from "../components/PageImageBanner";
import LoadingSpinner from "../components/LoadingSpinner.js";
import SlideShow from "../components/SlideShow.js";
import { BusinessImages } from "../services/ImageLists.js";

function OurServices() {
  const scaleWidth = GetScreenScale();
  const isSmallScreen = IsSmallScreen();
  const windowDimensions = getWindowDimensions();

  const [isSlideShowVisible, setIsSlideShowVisible] = React.useState(false);
  const [isSideImageVisible, setIsSideImageVisible] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);

    const slideShowTimeoutId = setTimeout(() => {
      setIsSlideShowVisible(true);
    }, 3000);

    const sideImageTimeoutId = setTimeout(() => {
      setIsSideImageVisible(true);
    }, 500);

    return () => {
      clearTimeout(slideShowTimeoutId);
      clearTimeout(sideImageTimeoutId);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: scaleWidth,
        margin: scaleWidth !== "100%" ? "auto" : "none",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Superior Turf-Sod Farm | Business Services</title>
        <link rel="canonical" href="https://stsf.com/business-services" />
        <meta
          name="description"
          content="We have the best sod-turf in the state!"
        />
      </Helmet>

      {/* Banner */}
      <PageImageBanner
        image="https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/STSF-Turf-Sod-Business-Services.jpg"
        text={"BUSINESS SERVICES"}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 2,
          paddingTop: "30px",
        }}
      >
        <div
          style={{
            display: windowDimensions.width < 1100 ? "none" : "flex",
            flex: 1,
            padding: "25px",
            justifyContent: "center", // centers child elements horizontally
            alignItems: "center", // centers child elements vertically
          }}
        >
          {/* <!-- Sideshow container --> */}
          {isSideImageVisible ? (
            <img
              src="https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/STSF-Turf-Sod-Mowing-Business-Services.jpg"
              width="100%"
              alt="Family Playing in Grass"
            />
          ) : (
            <LoadingSpinner />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            paddingTop: "25px",
            paddingBottom: "60px",
            paddingLeft: "60px",
            paddingRight: "60px",
          }}
        >
          <p className="stsfBody">
            Lawns established by our specialized blend of Turf-Type Tall
            Fescue/Bluegrass Mix sod create a <b>professional and clean</b> look
            for your business. Our professional installation services are
            available to complete the beautification of the project at hand.{" "}
            <br /> <br />
            Our hybrid turf-sod is grown to resist many of the diseases that can
            affect common-type grass. We continually look to improve our
            operations and methods to provide a{" "}
            <b>superior product and superior service for businesses.</b>
          </p>

          {/* GRASSY BULLET POINTS */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              margin: "auto",
            }}
          >
            <h1
              style={{
                textAlign: "left",
                paddingBottom: "25px",
                paddingTop: "50px",
              }}
              className="stsfHeaders"
            >
              TURF-SOD FOR YOUR BUSINESS
            </h1>
            <GrassyBulletPointText text="Our crew is trained to install sod on any terrain." />
            <GrassyBulletPointText text="We offer Big-Roll Sod installments - which results in less seams!" />
            <GrassyBulletPointText text="Our Turf-Sod is harvested fresh, providing the best outcome for your project." />
          </div>
        </div>
      </div>

      {/* <!-- Slideshow container --> */}
      {isSlideShowVisible ? (
        <SlideShow imagesWithDescriptions={BusinessImages} />
      ) : (
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
}

export default OurServices;
