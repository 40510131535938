import React, { Component } from "react";
import NavMenu from "./components/NavMenu";
import Footer from "./components/Footer";
import "./Layout.css";

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div className="pageBackground">
        {/* Set header and body to always take up enough space to keep footer at bottom */}
        <div style={{ minHeight: "95.5vh" }}>
          <NavMenu />
          <div>{this.props.children}</div>
        </div>
        <Footer />
      </div>
    );
  }
}
