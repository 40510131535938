export const ResidentialImages = [
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/residential/slide-show/Res1.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/residential/slide-show/Res10-01.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/residential/slide-show/Res11-01.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/residential/slide-show/Res12-01.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/residential/slide-show/Res13-01.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/residential/slide-show/Res14-01.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/residential/slide-show/Res15-01.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/residential/slide-show/Res16-01.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/residential/slide-show/Res2.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/residential/slide-show/Res3-01.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/residential/slide-show/Res4-01.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/residential/slide-show/Res5-01.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/residential/slide-show/Res6-01.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/residential/slide-show/Res7-01.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/residential/slide-show/Res8-01.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/residential/slide-show/Res9-01.jpg",
    description: "",
  },
];

export const BusinessImages = [
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/slide-show/STSF-Turf-Sod-Business-Services-16.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/slide-show/STSF-Turf-Sod-Business-Services-17.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/slide-show/STSF-Turf-Sod-Business-Services-18.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/slide-show/STSF-Turf-Sod-Business-Services-19.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/slide-show/STSF-Turf-Sod-Business-Services-2.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/slide-show/STSF-Turf-Sod-Business-Services-20.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/slide-show/STSF-Turf-Sod-Business-Services-21.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/slide-show/STSF-Turf-Sod-Business-Services-3.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/slide-show/STSF-Turf-Sod-Business-Services-4.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/slide-show/STSF-Turf-Sod-Business-Services-5.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/slide-show/STSF-Turf-Sod-Business-Services-1.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/slide-show/STSF-Turf-Sod-Business-Services-10.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/slide-show/STSF-Turf-Sod-Business-Services-11.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/slide-show/STSF-Turf-Sod-Business-Services-12.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/slide-show/STSF-Turf-Sod-Business-Services-13.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/slide-show/STSF-Turf-Sod-Business-Services-14.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/slide-show/STSF-Turf-Sod-Business-Services-15.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/slide-show/STSF-Turf-Sod-Business-Services-6.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/slide-show/STSF-Turf-Sod-Business-Services-7.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/slide-show/STSF-Turf-Sod-Business-Services-8.jpg",
    description: "",
  },
  {
    image:
      "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/business/slide-show/STSF-Turf-Sod-Business-Services-9.jpg",
    description: "",
  },
];
