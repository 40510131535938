import React from "react";
import { Helmet } from "react-helmet";
import {
  GetScreenScale,
  IsSmallScreen,
} from "../services/PageWidthCalculator.js";
import SiloFarms from "../images/testimonial/silofarms.png";

import Testimonial from "../components/Testimonial.js";
import UnknownPerson from "../svgs/Silouette-08.svg";
import PageImageBanner from "../components/PageImageBanner";

function Testimonails() {
  const scaleWidth = GetScreenScale();
  const isSmallScreen = IsSmallScreen();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: scaleWidth,
        margin: scaleWidth !== "100%" ? "auto" : "none",
        paddingBottom: "50px",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Superior Turf-Sod Farm | Testimonials</title>
        <link rel="canonical" href="https://stsf.com/testimonials" />
        <meta
          name="description"
          content="Catering to some of the most well know business in Kentucky!"
        />
      </Helmet>

      {/* Header */}
      <PageImageBanner
        image="https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/testimonials/STSF-Turf-Sod-Testimonial-Kentucky.jpg"
        text={"TESTIMONIALS"}
      />

      <Testimonial
        image={SiloFarms}
        imageAlt="Silo Farm Logo"
        testimonialBody="Big Thank You to Superior Turf-Sod Farm, Inc. This Local company came through to make sure we had a green back drop for our first event."
        company={"Silo Farms"}
        personAndTitle={"Owner"}
        link={"https://www.facebook.com/SiloFarmsKY/"}
      />
      <Testimonial
        image={UnknownPerson}
        imageAlt="Unknown Person"
        testimonialBody="This place is fantastic! What a great find. The sod is very high quality and their service is top notch. Absolutely the nicest folks you will ever meet."
        company={"Residential"}
        personAndTitle={"Jessica"}
        link={"https://g.co/kgs/zQztV6"}
      />
      <Testimonial
        image={UnknownPerson}
        imageAlt="Unknown Person"
        testimonialBody="Great customer service, good looking product. Will definitely use them and recommend them to others. Super nice people and very easy to talk too."
        company={"Residential"}
        personAndTitle={"Doug"}
        link={"https://g.co/kgs/2cBCjP"}
      />
      <Testimonial
        image={UnknownPerson}
        imageAlt="Unknown Person"
        testimonialBody="I picked up the sod this morning and your workers were great. I'm definitely going to recommend you to everyone I know. Thank you!"
        company={"S&M Lawn Care"}
        personAndTitle={"Kris Wackowski"}
        link={""}
      />
    </div>
  );
}

export default Testimonails;
