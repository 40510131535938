import React from "react";
import { IsSmallScreen } from "../services/PageWidthCalculator.js";

function PageImageBanner({ image, text }) {
  const isSmallScreen = IsSmallScreen();

  return (
    <div
      style={{
        backgroundImage: `url(${image})`,
        height: isSmallScreen ? "25vh" : "30vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        color: "white",
        fontFamily: "GolosText-Bold",
        fontSize: isSmallScreen ? "24pt" : "36pt",
        paddingRight: "1%",
        paddingBottom: "1%",
        userSelect: "none",
        marginBottom: "1%",
      }}
    >
      {text}
    </div>
  );
}

export default PageImageBanner;
