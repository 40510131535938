import React from "react";
import { Helmet } from "react-helmet";
import {
  GetScreenScale,
  IsSmallScreen,
} from "../services/PageWidthCalculator.js";

import PageImageBanner from "../components/PageImageBanner";

function TurfSodCare() {
  const scaleWidth = GetScreenScale();
  const isSmallScreen = IsSmallScreen();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: scaleWidth,
        margin: scaleWidth !== "100%" ? "auto" : "none",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Superior Turf-Sod Farm | Turf-Sod Care</title>
        <link rel="canonical" href="https://stsf.com/turfsodcare" />
        <meta
          name="description"
          content="With our easy to understand instructions, your Turf or Sod will continue looking great!"
        />
      </Helmet>

      {/* Banner */}
      <PageImageBanner
        image={
          "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/turfsod-care/STSF-Turf-Sod-Care-Cover-Image-Kentucky.jpg"
        }
        text={"TURF-SOD CARE"}
      />

      {/* Before Install */}
      <div
        style={{ display: "flex", flexDirection: "column", padding: "30px" }}
      >
        <h1
          style={{
            textAlign: "left",
            padding: "25px",
          }}
          className="stsfHeaders"
        >
          BEFORE INSTALLATION
        </h1>
        <p className="stsfBody" style={{ paddingLeft: "25px" }}>
          Installing Turf-Sod is essentially the same as transplanting a plant
          in a garden. The better you prepare beforehand, the easier the install
          and the better the results in the long term. For Turf-Sod preparation
          you may do it yourself or have a professional prepare the area. If you
          need a referral for this just contact us. <br /> <br />
          Before install, the area should be free of undesirable grass or weeds.
          Once all weeds and grass are removed you can then till and rake the
          area smooth or you can rent a walk-behind harley rake to prepare the
          soil. Make sure to remove any type of rocks that may be in the area as
          well. At this point, you should also be considering any additional
          drainage needs. While Turf-Sod does absorb water, it does not correct
          drainage issues. <br /> <br />
          Finally, it is important not to fertilize or water the ground before
          installation. Fertilization before installation could damage your
          roots. Watering will create a muddy enviornment that is not condusive
          to a successful install.
        </p>
      </div>

      {/* After Install */}
      <div
        style={{ display: "flex", flexDirection: "column", padding: "30px" }}
      >
        <h1
          style={{
            textAlign: "left",
            padding: "25px",
          }}
          className="stsfHeaders"
        >
          AFTER INSTALLATION
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              padding: "25px",
            }}
          >
            <div
              style={{
                backgroundImage: `url(https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/turfsod-care/STSF-Turf-Sod-Care-Watering-Hose.jpg)`,
                width: "100%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontFamily: "GolosText-Bold",
                fontSize: isSmallScreen ? "24pt" : "36pt",
                height: "5em",
                userSelect: "none",
              }}
            >
              WATERING
            </div>
            <p className="stsfBody" style={{ paddingTop: "10px" }}>
              Immediately after installation, water your new turf-sod to the
              point that the soil under is wet to a depth of approximately 3
              inches ("spongy" to walk on). This should be maintained for 10- 14
              days. Depending on the time of year, you may need to water daily
              or less frequently to maintain the "spongy" effect. Gradually
              reduce the amount of water over time after the initial 10-14 days.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              padding: "25px",
            }}
          >
            <div
              style={{
                backgroundImage: `url(https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/turfsod-care/STSF-Turf-Sod-Care-Mowing.jpg)`,
                width: "100%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontFamily: "GolosText-Bold",
                fontSize: isSmallScreen ? "24pt" : "36pt",
                height: "5em",
                userSelect: "none",
              }}
            >
              MOWING
            </div>
            <p className="stsfBody" style={{ paddingTop: "10px" }}>
              Approximately 14 days after installation, you should be able to
              mow. You can check by trying to pull up a roll of sod - if it is
              firmly attached to the soil... you should be safe. The first few
              times you mow you should set the mower to a higher deck height and
              bag clippings if possible. Try not to turn on the new sod if
              possible. After a couple of mowings the clippings are okay to be
              left and mulched. Mulching actually benefits the Turf-Sod in the
              long run.
            </p>
          </div>
        </div>
      </div>

      {/* Upkeep Schedule */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "30px",
          backgroundColor: "#01683B",
          marginBottom: "50px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            padding: "30px",
            flex: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              padding: "25px",
            }}
          >
            <div
              style={{
                backgroundImage: `url(https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/turfsod-care/STSF-Turf-Sod-Care-Spring.jpg)`,
                width: "100%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontFamily: "GolosText-Bold",
                fontSize: isSmallScreen ? "24pt" : "36pt",
                height: "5em",
                userSelect: "none",
              }}
            >
              SPRING
            </div>
            <p
              className="stsfBody"
              style={{
                paddingTop: "10px",
                color: "white",
              }}
            >
              <i>(MID-MARCH TO EARLY-APRIL)</i> <br />
              Use a weed and feed type fertilizer that includes a{" "}
              <b>broadleaf and pre-emergent crabgrass control</b>. A second
              pre-emergent can be applied later in the season if necessary.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              padding: "25px",
            }}
          >
            <div
              style={{
                backgroundImage: `url(https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/turfsod-care/STSF-Turf-Sod-Care-Fall.jpg)`,
                width: "100%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontFamily: "GolosText-Bold",
                fontSize: isSmallScreen ? "24pt" : "36pt",
                height: "5em",
                userSelect: "none",
              }}
            >
              FALL
            </div>
            <p
              className="stsfBody"
              style={{
                paddingTop: "10px",
                color: "white",
              }}
            >
              <i>(LATE SEPTEMBER, EARLY OCTOBER)</i> <br />
              Apply an application of <b>fertilizer and weed control </b>of
              broadleaf and dandelion to prevent spring weeds.
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            padding: "30px",
            flex: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              padding: "25px",
            }}
          >
            <div
              style={{
                backgroundImage: `url(https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/turfsod-care/STSF-Turf-Sod-Care-Summer.jpg)`,
                width: "100%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontFamily: "GolosText-Bold",
                fontSize: isSmallScreen ? "24pt" : "36pt",
                height: "5em",
                userSelect: "none",
              }}
            >
              SUMMER
            </div>
            <p
              className="stsfBody"
              style={{
                paddingTop: "10px",
                color: "white",
              }}
            >
              <i>(MAY, EARLY JUNE)</i> <br />
              Apply a light application of{" "}
              <b>fertilizer with a high Nitrogen content </b>(the first number
              on the bag). If your crabgrass was not controlled in the early
              spring use a post-emergent control for crabgrass. This will help
              maintain a deep green color.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              padding: "25px",
            }}
          >
            <div
              style={{
                backgroundImage: `url(https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/turfsod-care/STSF-Turf-Sod-Care-Winter.jpg)`,
                width: "100%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontFamily: "GolosText-Bold",
                fontSize: isSmallScreen ? "24pt" : "36pt",
                height: "5em",
                userSelect: "none",
              }}
            >
              WINTER
            </div>
            <p
              className="stsfBody"
              style={{
                paddingTop: "10px",
                color: "white",
              }}
            >
              <i>(LATE NOVEMBER, EARLY DECEMBER)</i> <br />
              Apply <b>fertilizer high in Nitrogen </b>(i,e. 46-0-0). This will
              help promote vigorous root activity throughout the winter which
              will strengthen your root zone and promote a healthy and early
              spring green-up.
            </p>
          </div>
        </div>
        <p
          className="stsfBody"
          style={{
            paddingTop: "10px",
            display: "flex",
            color: "white",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            marginLeft: "10%",
            marginRight: "10%",
          }}
        >
          Ideally every couple of years it would be beneficial to soil sample
          your turf to get an exact need of the fertilization required for
          continued success.
        </p>
      </div>
    </div>
  );
}

export default TurfSodCare;
