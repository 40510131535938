import React, { useState } from "react";
import Collapsible from "react-collapsible";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import ReactMarkdown from "react-markdown";
import "../pages/shared.css";

function CollapsibleInfo({ question, answer }) {
  const formattedAnswer = answer.replace(/<br\s*\/?>/gi, "  \n");
  const [expanded, setExpanded] = useState(false);
  const icon = expanded ? faMinus : faPlus;

  function toggleExpanded() {
    setExpanded(!expanded);
  }

  return (
    <Collapsible
      trigger={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "1em",
            paddingTop: ".5em",
            paddingBottom: ".5em",
          }}
        >
          <span
            style={{
              textAlign: "left",
              fontSize: "16pt",
            }}
            className="stsfHeaders"
          >
            {question}
          </span>
          <span style={{ textAlign: "right", paddingRight: "10px" }}>
            <FontAwesomeIcon
              icon={icon}
              style={{ fontSize: "20pt", color: "#01683b" }}
            />
          </span>
        </div>
      }
      onOpening={toggleExpanded}
      onClosing={toggleExpanded}
    >
      <div style={{ paddingLeft: "2em", color: "#01683b" }}>
        <ReactMarkdown style={{ color: "#01683b" }}>
          {formattedAnswer}
        </ReactMarkdown>
      </div>
    </Collapsible>
  );
}

export default CollapsibleInfo;
