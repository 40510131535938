import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./NavMenu.css";
import BarsSolid from "../svgs/bars-solid.svg";
import Drawer from "@mui/material/Drawer";
import { pageWidths } from "../page-widths";
import stsflogo from "../images/GreenMainLogo.png";
import "../pages/shared.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { faPersonCircleQuestion } from "@fortawesome/free-solid-svg-icons";

function NavMenu() {
  const [width, setWidth] = React.useState(window.innerWidth);

  let [drawerOpen, setDrawerOpen] = React.useState(false);

  let location = useLocation();

  React.useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <header
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        position: width < pageWidths.smallWidth ? "sticky" : "none",
        top: width < pageWidths.smallWidth ? 0 : "none",
        paddingBottom: "15px",
        backgroundColor: "rgba(239, 252, 239, 0.8)",
        zIndex: 2,
      }}
    >
      <div
        style={{
          display: width > 1025 ? "flex" : "none",
          flexDirection: "column",
          flex: 3,
        }}
      >
        {/* Floating info bar*/}
        <div
          style={{
            backgroundColor: "#093823",
            paddingBottom: "10px",
            paddingTop: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "15px",
          }}
        >
          <div
            style={{
              color: "#EFFCEF",
              paddingLeft: "50px",
              display: "flex",
              fontFamily: "GolosText-Regular, arial",
              flex: 3,
              alignContent: "center",
            }}
          >
            <b>LOCATION HOURS: MON-SAT &ensp;</b>
            <i>
              <span style={{}}>BY APPOINTMENT ONLY</span>
            </i>
          </div>
          <div
            style={{
              color: "#EFFCEF",
              paddingRight: "50px",
              display: "flex",
              justifyContent: "end",
              fontFamily: "arial",
              flex: 1,
            }}
          >
            <Link to="/contact">
              <FontAwesomeIcon
                icon={faPersonCircleQuestion}
                size="2x"
                className="socialIcon"
                style={{ paddingRight: "15px" }}
              />
            </Link>

            <a
              href="https://www.facebook.com/profile.php?id=102530340816997"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faFacebookSquare}
                size="2x"
                className="socialIcon"
              />
            </a>
          </div>
        </div>

        {/* Header Navigation on Medium/Big Screen */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 3,
            paddingRight: "25px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              paddingTop: ".25rem",
              flex: 1,
              paddingLeft: "1em",
            }}
          >
            <Link to="/">
              <img src={stsflogo} height="65" alt="STSF header logo" />
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "center",
              flex: 3,
            }}
          >
            <Link
              to="/"
              className={
                location.pathname === "/" ? "navItemSelected" : "navItem"
              }
              style={{
                paddingLeft: ".45em",
                paddingRight: ".45em",
              }}
            >
              Home
            </Link>
            <b>|</b>
            <div
              className="navItem dropdown"
              onMouseLeave={() => setDropdownOpen(false)}
            >
              <div
                className={
                  location.pathname === "/residential-services" ||
                  location.pathname === "/business-services"
                    ? "selectedNavLink"
                    : "navLink"
                }
                onClick={toggleDropdown}
              >
                Services
                <i className="fas fa-caret-down"></i>
              </div>
              {dropdownOpen && (
                <div className="dropdownMenu">
                  <Link
                    to="/residential-services"
                    onClick={() => setDropdownOpen(false)}
                    className={
                      location.pathname === "/residential-services"
                        ? "dropdownItem navItemSelected"
                        : "dropdownItem navItem"
                    }
                  >
                    Residential
                  </Link>
                  <Link
                    to="/business-services"
                    onClick={() => setDropdownOpen(false)}
                    className={
                      location.pathname === "/business-services"
                        ? "navItemSelected dropdownItem"
                        : "navItem dropdownItem"
                    }
                  >
                    Business
                  </Link>
                </div>
              )}
            </div>
            <b>|</b>
            <Link
              to="/turfsodcare"
              className={
                location.pathname === "/turfsodcare"
                  ? "navItemSelected"
                  : "navItem"
              }
              style={{
                paddingLeft: ".45em",
                paddingRight: ".45em",
                wordBreak: "keep-all",
                whiteSpace: "nowrap",
              }}
            >
              Turf-Sod Care
            </Link>
            <b>|</b>
            <Link
              to="/testimonials"
              className={
                location.pathname === "/testimonials"
                  ? "navItemSelected"
                  : "navItem"
              }
              style={{
                paddingLeft: ".45em",
                paddingRight: ".45em",
              }}
            >
              Testimonials
            </Link>
            <b>|</b>
            <Link
              to="/about"
              className={
                location.pathname === "/about" ? "navItemSelected" : "navItem"
              }
              style={{
                paddingLeft: ".45em",
                paddingRight: ".45em",
              }}
            >
              About
            </Link>
            <b>|</b>
            <Link
              to="/contact"
              className={
                location.pathname === "/contact" ? "navItemSelected" : "navItem"
              }
              style={{
                paddingLeft: ".45em",
                paddingRight: ".45em",
              }}
            >
              Contact
            </Link>
          </div>
        </div>
      </div>

      {/* Header Navigation on Small Screen */}
      <div
        style={{
          display: width <= 1025 ? "flex" : "none",
          flexDirection: "row",
          justifyContent: "center",
          flex: 3,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            paddingRight: "1%",
            paddingTop: ".25rem",
            paddingBottom: ".25rem",
            flex: 1,
          }}
        >
          <Link to="/">
            <img src={stsflogo} height="65" alt="STSF Mobile Icon" />
          </Link>
        </div>
        <div
          style={{
            display: width <= 1025 ? "flex" : "none",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            flex: 3,
          }}
        >
          <div
            onClick={() => setDrawerOpen(true)}
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              paddingRight: "35px",
            }}
          >
            <img
              src={BarsSolid}
              width={width > 400 ? "30px" : "25px"}
              alt="Drawer Open Icon"
            />
          </div>
          <Drawer
            anchor={"top"}
            open={drawerOpen}
            onClose={() => setDrawerOpen(!drawerOpen)}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link
                to="/"
                className={
                  location.pathname === "/" ? "navItemSelected" : "navItem"
                }
                style={{
                  padding: ".45em",
                  fontSize: "1.4em",
                }}
                onClick={() => setDrawerOpen(!drawerOpen)}
              >
                Home
              </Link>
              <Link
                to="/residential-services"
                className={
                  location.pathname === "/residential-services"
                    ? "navItemSelected"
                    : "navItem"
                }
                style={{
                  padding: ".45em",
                  fontSize: "1.4em",
                }}
                onClick={() => setDrawerOpen(!drawerOpen)}
              >
                Residential Services
              </Link>

              <Link
                to="/business-services"
                className={
                  location.pathname === "/business-services"
                    ? "navItemSelected"
                    : "navItem"
                }
                style={{
                  padding: ".45em",
                  fontSize: "1.4em",
                }}
                onClick={() => setDrawerOpen(!drawerOpen)}
              >
                Business Services
              </Link>

              <Link
                to="/turfsodcare"
                className={
                  location.pathname === "/turfsodcare"
                    ? "navItemSelected"
                    : "navItem"
                }
                style={{
                  padding: ".45em",

                  fontSize: "1.4em",
                }}
                onClick={() => setDrawerOpen(!drawerOpen)}
              >
                Turf-Sod Care
              </Link>

              <Link
                to="/testimonials"
                className={
                  location.pathname === "/testimonials"
                    ? "navItemSelected"
                    : "navItem"
                }
                style={{
                  padding: ".45em",

                  fontSize: "1.4em",
                }}
                onClick={() => setDrawerOpen(!drawerOpen)}
              >
                Testimonials
              </Link>

              <Link
                to="/about"
                className={
                  location.pathname === "/about" ? "navItemSelected" : "navItem"
                }
                style={{
                  padding: ".45em",

                  fontSize: "1.4em",
                }}
                onClick={() => setDrawerOpen(!drawerOpen)}
              >
                About
              </Link>

              <Link
                to="/contact"
                className={
                  location.pathname === "/contact"
                    ? "navItemSelected"
                    : "navItem"
                }
                style={{
                  padding: ".45em",

                  fontSize: "1.4em",
                }}
                onClick={() => setDrawerOpen(!drawerOpen)}
              >
                Contact
              </Link>
            </div>
          </Drawer>
        </div>
      </div>
    </header>
  );
}

export default NavMenu;
