import React from "react";
import "./Footer.css";

function Footer() {
  let curentYear = new Date().getFullYear();
  return (
    <footer
      style={{
        fontSize: "13pt",
        backgroundColor: "#093823",
        border: "1px solid #093823",
        color: "white",
        fontFamily: "GolosText-Bold",
        justifyContent: "center",
        display: "flex",
        alignContent: "center",
        padding: "1em",
        position: "relative",
        left: 0,
        bottom: 0,
        right: 0,
      }}
    >
      <div style={{ display: "flex", flex: 1, justifyContent: "flex-start" }}>
        &#169;1997-{curentYear} by Superior Turf-Sod Farm, Inc.
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "flex-end",
          textAlign: "right",
        }}
      >
        <a
          href="https://www.radixsoftwaresolutions.com/"
          className="radix-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Proudly Created and Hosted by Radix Software Solutions LLC
        </a>
      </div>
    </footer>
  );
}

export default Footer;
