import React from "react";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import Home from "./pages/Home";
import ResidentialServices from "./pages/ResidentialServices";
import BusinessServices from "./pages/BusinessServices";
import Testimonails from "./pages/Testimonials";
import TurfSodCare from "./pages/TurfSodCare";

const AppRoutes = [
  {
    index: true,
    element: <Home />,
  },
  {
    path: "/business-services",
    element: <BusinessServices />,
  },
  {
    path: "/residential-services",
    element: <ResidentialServices />,
  },
  {
    path: "/turfsodcare",
    element: <TurfSodCare />,
  },
  {
    path: "/testimonials",
    element: <Testimonails />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/contact",
    element: <ContactUs />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/*",
    element: <Home />,
  },
];

export default AppRoutes;
