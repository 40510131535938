import React from "react";
import { Helmet } from "react-helmet";
import {
  GetScreenScale,
  getWindowDimensions,
} from "../services/PageWidthCalculator.js";
import "./shared.css";
import GrassyBulletPointText from "../components/GrassyBulletPointTextGreen.js";
import PageImageBanner from "../components/PageImageBanner";
import LoadingSpinner from "../components/LoadingSpinner.js";
import SlideShow from "../components/SlideShow.js";
import { ResidentialImages } from "../services/ImageLists.js";

function ResidentialServices() {
  const scaleWidth = GetScreenScale();
  const windowDimensions = getWindowDimensions();

  const [isSlideShowVisible, setIsSlideShowVisible] = React.useState(false);
  const [isSideImageVisible, setIsSideImageVisible] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);

    const slideShowTimeoutId = setTimeout(() => {
      setIsSlideShowVisible(true);
    }, 3000);

    const sideImageTimeoutId = setTimeout(() => {
      setIsSideImageVisible(true);
    }, 500);

    return () => {
      clearTimeout(slideShowTimeoutId);
      clearTimeout(sideImageTimeoutId);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: scaleWidth,
        margin: scaleWidth !== "100%" ? "auto" : "none",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Superior Turf-Sod Farm | Residential Services</title>
        <link rel="canonical" href="https://stsf.com/residential-services" />
        <meta
          name="description"
          content="We have the best sod-turf in the state!"
        />
      </Helmet>

      {/* Banner */}
      <PageImageBanner
        image={
          "https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/residential/Residential.Cover-01.jpg"
        }
        text={"RESIDENTIAL SERVICES"}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 2,
          paddingTop: "30px",
        }}
      >
        <div
          style={{
            display: windowDimensions.width < 1100 ? "none" : "flex",
            flex: 1,
            padding: "25px",
            justifyContent: "center", // centers child elements horizontally
            alignItems: "center", // centers child elements vertically
          }}
        >
          {/* <!-- Sideshow container --> */}
          {isSideImageVisible ? (
            <img
              src="https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/residential/ResidentialStock-01.jpg"
              width="100%"
              alt="Family Playing in Grass"
            />
          ) : (
            <LoadingSpinner />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            paddingTop: "25px",
            paddingBottom: "60px",
            paddingLeft: "60px",
            paddingRight: "60px",
          }}
        >
          <p className="stsfBody">
            Lawns established by our specialized blend of Turf-Type Tall
            Fescue/Bluegrass mix sod are an immediate source of{" "}
            <b>joy and pride</b>. Lawns started with seed take 2 to 3 years of
            continual effort which can be a source of frustration.
            <br />
            <br />
            Seed may seem cheaper in the beginning, but by the time the lawn is
            finally mature, Turf-Sod may have been the{" "}
            <b>
              cheaper and <u>definitely a quicker beautification</u>
            </b>
            .
          </p>

          {/* GRASSY BULLET POINTS */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              margin: "auto",
            }}
          >
            <h1
              style={{
                textAlign: "left",
                paddingBottom: "25px",
                paddingTop: "50px",
              }}
              className="stsfHeaders"
            >
              TURF-SOD FOR YOUR HOME
            </h1>
            <GrassyBulletPointText text="Turf-Sod is one of the fastest ways to get the green lawn of your dreams." />
            <GrassyBulletPointText text="Compared to seeding, Turf-Sod is equally or less expensive in the long run." />
            <GrassyBulletPointText text="Not only does it save money, Turf-Sod also requires MUCH less work to establish." />
            <GrassyBulletPointText text="Turf-Sod is one of the most cost-effective methods of controlling water erosion." />
            <GrassyBulletPointText text="Our Turf-Sod is grown to resist diseases found in common grass types. Resulting in time saved down the road!" />
          </div>
        </div>
      </div>

      {/* <!-- Slideshow container --> */}
      {isSlideShowVisible ? (
        <SlideShow imagesWithDescriptions={ResidentialImages} />
      ) : (
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
}

export default ResidentialServices;
