import React from "react";
import { Helmet } from "react-helmet";
import {
  GetScreenScale,
  IsSmallScreen,
} from "../services/PageWidthCalculator.js";

import "./shared.css";

function About() {
  const scaleWidth = GetScreenScale();
  const isSmallScreen = IsSmallScreen();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: scaleWidth,
        margin: scaleWidth !== "100%" ? "auto" : "none",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Superior Turf-Sod Farm | About</title>
        <link rel="canonical" href="http://stsf.com/about" />
        <meta
          name="description"
          content="Serving Kentucky for over 45 years, we have the superior sod and service for residential and business needs."
        />
      </Helmet>

      {/* Header */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: "50px",
        }}
      >
        <h1
          style={{ textAlign: "center", fontSize: "34pt", paddingTop: "50px" }}
          className="stsfHeaders"
        >
          <i>SERVING KENTUCKY SINCE 1997</i>
        </h1>
      </div>

      {/* Section One */}
      <div
        style={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          marginTop: "2em",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            margin: "1em",
            flexDirection: "row",
            flex: 3,
          }}
        >
          <img
            src="https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/about/STSF-Turf-Sod-History-About-Page.jpg"
            alt="Half Harvested Sod Field"
            width="100%"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "1em",
            fontSize: "14pt",
            flex: 3,
            padding: "5px",
          }}
          className="stsfBody"
        >
          <h3
            style={{
              textAlign: "left",
              fontSize: "20pt",
            }}
            className="stsfHeaders"
          >
            HISTORY
          </h3>
          Superior Turf-Sod Farm, Inc. (STSF) was “born” March 17, 1997, when
          Jeff, Monica, and their son, Grant Hagerman purchased a property in
          Shelbyville, Kentucky. The property originally caught their attention
          purely for its pristine setting and aesthetic appeal. This location
          served as the perfect place to grow STSF.
        </div>
      </div>

      {/* Section Two */}
      <div
        style={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          marginTop: "2em",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "1em",
            fontSize: "14pt",
            flex: 3,
            padding: "5px",
          }}
          className="stsfBody"
        >
          <h3
            style={{
              textAlign: "left",
              fontSize: "20pt",
            }}
            className="stsfHeaders"
          >
            FAMILY
          </h3>
          While large enough to handle sizable sod jobs, the Hagermans pride
          themselves on running the company as a family business— small enough
          to be personable, yet large enough to be professional. As true with
          any “family farm” atmosphere, we invite you to stay after picking up
          your sod to enjoy the beautiful views at the farm.
        </div>
        <div
          style={{
            display: "flex",
            margin: "1em",
            flexDirection: "row",
            flex: 3,
          }}
        >
          <img
            src="https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/about/STSF-Turf-Sod-Family-About-Page.jpg"
            alt="Unharvested Sod Field"
            width="100%"
          />
        </div>
      </div>

      {/* Section Three */}
      <div
        style={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          marginTop: "2em",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: isSmallScreen ? "none" : "flex",
            margin: "1em",
            flexDirection: "row",
            flex: 3,
          }}
        >
          <img
            src="https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/about/STSF-Turf-Sod-Experience-About-Page.jpg"
            alt="Full Sod Field near Water Source"
            width="100%"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "1em",
            fontSize: "14pt",
            flex: 3,
            padding: "5px",
          }}
          className="stsfBody"
        >
          <h3
            style={{
              textAlign: "left",
              fontSize: "20pt",
            }}
            className="stsfHeaders"
          >
            EXPERIENCE
          </h3>
          With Jeff and Monica both having widely varied employment backgrounds,
          they knew they had the expertise to put together a business with the
          propensity to grow. Jeff’s background included sales and marketing
          while Monica came from a farming family and had experience with both
          office management and customer service. Combined, these skills result
          in a working relationship that covers all the bases!
        </div>
        <div
          style={{
            display: isSmallScreen ? "flex" : "none",
            margin: "1em",
            flexDirection: "row",
            flex: 2,
          }}
        >
          <img
            src="https://rss-web-images.s3.us-east-2.amazonaws.com/images/stsf/about/STSF-Turf-Sod-Experience-About-Page.jpg"
            alt="Full Sod Field near Water Source"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
}

export default About;
