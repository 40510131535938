import React from "react";
import ReactMarkdown from "react-markdown";
import {
  GetScreenScale,
  getWindowDimensions,
} from "../services/PageWidthCalculator.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Testimonial({
  image,
  imageAlt,
  testimonialBody,
  company,
  personAndTitle,
  link,
}) {
  const formattedTestimonialBody = testimonialBody.replace(
    /<br\s*\/?>/gi,
    "  \n"
  );
  const scaleWidth = GetScreenScale();
  const windowDimensions = getWindowDimensions();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "40px",
        paddingBottom: "35px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          border: "1px solid grey",
          flex: 3,
          marginLeft: "5%",
          marginRight: "5%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: windowDimensions.width > 600 ? 1 : 5,
            alignItems: "center",
            justifyContent: "center",
            padding: windowDimensions.width > 600 ? "1em" : "none",
          }}
        >
          <img
            src={image}
            alt={imageAlt}
            width="100%"
            style={{ paddingLeft: "15px", paddingRight: "15px" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 10,
            width: "100%",
            alignContent: "center",
            justifyContent: "center",
            paddingRight: "25px",
            paddingLeft: "25px",
          }}
        >
          <ReactMarkdown>{formattedTestimonialBody}</ReactMarkdown>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            width: "50%",
            alignContent: "center",
            justifyContent: "center",
            paddingRight: "10px",
          }}
        >
          {link && (
            <Link to={link} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon
                icon={faUpRightFromSquare}
                size="2x"
                color="#01683b"
              />
            </Link>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          flex: 1,
          textAlign: "center",
        }}
      >
        {company}
        <br />
        {personAndTitle}
        <br />
      </div>
    </div>
  );
}

export default Testimonial;
