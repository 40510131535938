import React from "react";
import {
  GetScreenScale,
  getWindowDimensions,
} from "../services/PageWidthCalculator.js";
import { Slide } from "react-slideshow-image";

function SlideShow({ imagesWithDescriptions }) {
  const scaleWidth = GetScreenScale();
  const windowDimensions = getWindowDimensions();

  return (
    <div
      style={{
        width: scaleWidth,
        margin: scaleWidth !== "100%" ? "auto" : "none",
        paddingBottom: "35px",
      }}
    >
      <Slide>
        {imagesWithDescriptions.map((image, index) => (
          <div className="each-slide-effect" key={index}>
            <div
              style={{
                backgroundImage: `url(${image.image})`,
                backgroundSize:
                  windowDimensions.height > 800 ? "none" : "contain",
              }}
            >
              <span>{image.description}</span>
            </div>
          </div>
        ))}
      </Slide>
    </div>
  );
}

export default SlideShow;
